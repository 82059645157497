export const removeMultipleSpaces = string => {
  return string.replace(/\s\s+/g, " ");
};

export const formatString = (value, pattern, placeholder) => {
  if (!value) return "";

  let cursor = 0;
  return pattern.replace(/#/g, (patternValue, idx) => {
    return value[cursor++] || placeholder[idx] || patternValue;
  });
};

export const removeChar = (str, char) => {
  const splittedString = str.split(char);
  return splittedString.map(str => str.trim()).join("");
};
