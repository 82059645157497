import { mapState, mapActions } from "vuex";

import SuccessMessage from "@/components/SuccessMessage/SuccessMessage.vue";
import ErrorMessage from "@/components/ErrorMessage/ErrorMessage.vue";
import Message from "@/components/Message/Message.vue";
import * as types from "@/types/store";
import { localizedMessage } from "@/utils/locale";

export const PAYMENT_STATUS = Object.freeze({
  new: "new",
  pending: "pending",
  paid: "paid",
  error: "error",
  refund: "refund",
  rejected: "rejected",
});

export default {
  name: "Result",
  components: {
    SuccessMessage,
    ErrorMessage,
    Message,
  },
  created() {
    const { id } = this.$route.query;

    if (!id) {
      this.redirectToDashboard();
      return;
    }

    this.paymentResult(this.transactionId);
  },
  computed: {
    ...mapState({
      language: state => state.i18n.language,
      loading: state => state.paymentResult.loading,
      error: state => state.paymentResult.error,
      redirect: state => state.paymentResult.redirect,
      paymentStatus: state => state.paymentResult.status,
    }),
    transactionId() {
      const { transactionId } = this.$route.params;
      return transactionId;
    },
    redirectUrl() {
      const { redirect_url } = this.redirect;
      return redirect_url;
    },
    statusMessage() {
      const { message } = this.paymentStatus;
      return localizedMessage(message, this.language);
    },
    messageType() {
      const { status } = this.paymentStatus;

      return (
        {
          [PAYMENT_STATUS.new]: "info",
          [PAYMENT_STATUS.pending]: "warning",
          [PAYMENT_STATUS.paid]: "success",
          [PAYMENT_STATUS.refund]: "success",
          [PAYMENT_STATUS.error]: "danger",
          [PAYMENT_STATUS.rejected]: "danger",
        }[status] || "danger"
      );
    },
    heading() {
      const { status } = this.paymentStatus;

      if (!this.isStatusSupported) {
        return this.$t("CORE.TITLE.ERROR");
      }

      const title = this.error ? "ERROR" : status.toUpperCase();
      return this.$t(`CORE.TITLE.${title}`);
    },
    message() {
      const { status } = this.paymentStatus;

      if (!status) {
        return this.$t("CORE.INFO.TRANSACTION_ERROR");
      }

      if (!this.isStatusSupported) {
        return this.$t("CORE.INFO.STATUS_NOT_SUPPORTED");
      }

      const msg = this.error ? "ERROR" : status.toUpperCase();
      return this.statusMessage || this.$t(`CORE.INFO.TRANSACTION_${msg}`);
    },
    isStatusSupported() {
      const { status } = this.paymentStatus;
      return status && Object.keys(PAYMENT_STATUS).includes(status);
    },
    isError() {
      const { status } = this.paymentStatus;
      return this.error || status === PAYMENT_STATUS.error;
    },
    isSuccess() {
      const { status } = this.paymentStatus;
      return status === PAYMENT_STATUS.paid;
    },
    isOtherStatus() {
      return !this.isSuccess && !this.isError;
    },
  },
  methods: {
    ...mapActions({
      getPaymentResults: types.ACTION_PAYMENT_RESULT_GET,
    }),
    redirectToService() {
      window.location.assign(this.redirectUrl);
    },
    redirectToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    async paymentResult(transactionId) {
      const response = await this.getPaymentResults(transactionId);

      if (response.error) {
        this.redirectToDashboard();
      }
    },
  },
};
