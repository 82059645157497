import Message from "@/components/Message/Message.vue";
import Counter from "@/components/Counter/Counter.vue";

export default {
  name: "SuccessMessage",
  components: {
    Message,
    Counter,
  },
  props: {
    redirectUrl: {
      type: String,
    },
  },
};
