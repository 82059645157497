import "@/assets/styles/css/customHyperPayStyles.css";

import { mapState } from "vuex";
import { logger } from "@/services/logger";

import CreditCard from "@/components/CreditCard/CreditCard.vue";
import { createWpwlOptions, registerForm, shouldFlipCard, FLIP_CART_EVENT } from "./PaymentForm.utils";
import { removeChar } from "@/utils/string";
import madaIcon from "@/assets/images/brands/mada_dark.svg";
import visaIcon from "@/assets/images/brands/visa_dark.svg";
import masterIcon from "@/assets/images/brands/master.svg";
import amexIcon from "@/assets/images/brands/amex.svg";

export default {
  name: "PaymentForm",
  components: {
    CreditCard,
  },
  props: {
    paymentWidgetsFunction: {
      type: String,
    },
    transactionId: {
      type: String,
    },
  },
  created() {
    this.visaIcon = visaIcon;
    this.masterIcon = masterIcon;
    this.madaIcon = madaIcon;
    this.amexIcon = amexIcon;
  },
  mounted() {
    window.addEventListener("message", this.onMessage);
    this.createForm();
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },
  data: () => ({
    form: {
      brand: "",
      name: "",
      date: "",
    },
    flip: false,
    isCardHidden: false,
    defaultBrands: ["VISA", "MASTER", "MADA", "AMEX"],
    widgetError: true,
    isInitialForm: true,
  }),
  computed: {
    ...mapState({
      language: state => state.i18n.language,
      paymentType: state => state.paymentType.type,
    }),
    redirectUrl() {
      return `${window.location.origin}/${this.transactionId}/result`;
    },
    widgetBrands() {
      const brands = this.defaultBrands;
      if (brands && Array.isArray(brands)) {
        return brands.join(" ");
      }
      return "";
    },
    normalizedPaymentWidgetsFunction() {
      return this.paymentWidgetsFunction.trim().replace(/;$/, "");
    },
  },
  methods: {
    createWidget() {
      try {
        window.wpwlOptions = createWpwlOptions(this.language);

        this.$nextTick(() => {
          eval(this.normalizedPaymentWidgetsFunction);
        });
      } catch (error) {
        logger.logError({ message: `Widget error: ${error}`, error });
      }
    },
    createForm() {
      this.resetCreditCard();
      this.createWidget();
    },
    onMessage(event) {
      if (event.origin !== window.origin) {
        if (shouldFlipCard(event)) {
          this.handleMessage(FLIP_CART_EVENT);
        }
        return;
      }

      const { data } = event;
      this.handleMessage(data);
    },
    handleMessage(data) {
      if (data.type === "flip") {
        this.flip = !!data.value;
        return;
      }

      if (data.type === "change") {
        this.changeFormValue(data.key, data.value);
        return;
      }

      if (data.type === "hide-card") {
        this.isCardHidden = true;
        return;
      }

      if (data.type === "error") {
        const { message, error } = data.value;

        logger.logError({ message: `Iframe error: ${message}`, error });
      }
    },
    changeFormValue(key, value) {
      if (!(key in this.form)) {
        return;
      }

      if (typeof value !== "string" && value !== null) {
        return;
      }

      let formattedValue = value;

      if (key === "date") {
        const CHAR = "/";
        formattedValue = value.includes(CHAR) ? removeChar(value, CHAR) : value;
      }

      if (this.isInitialForm && key === "brand") {
        this.form[key] = null;
        this.isInitialForm = false;
      } else {
        this.form[key] = formattedValue;
      }
    },
    resetCreditCard() {
      this.form = {
        brand: "",
        name: "",
        date: "",
      };
    },
    reloadWidget() {
      if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
        window.wpwl.unload();
        document.querySelectorAll("script").forEach(script => {
          if (script.src.includes("static.min.js")) {
            script.remove();
          }
        });
        const parent = this.$refs.formParent;
        registerForm(parent, this.redirectUrl, this.widgetBrands);
        this.createForm();
      }
    },
  },
  watch: {
    language() {
      this.reloadWidget();
    },
  },
};
