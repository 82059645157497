import { LOCALES } from "@/constants";

const getSupportedLocale = locale => {
  return Object.values(LOCALES).includes(locale) ? locale : "ar";
};

export const formatCurrency = ({ value, currency, locale }) => {
  const supportedLocale = getSupportedLocale(locale);

  return new Intl.NumberFormat(`${supportedLocale}-u-nu-latn`, {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
  }).format(value);
};
