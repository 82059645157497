import { mapState } from "vuex";
import { formatCurrency } from "@/utils/currency";

export default {
  name: "PaymentPrice",
  props: {
    value: {
      type: Number,
    },
    currency: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      language: state => state.i18n.language,
    }),
    price() {
      if (!this.value || !this.currency) {
        return "";
      }

      return formatCurrency({
        value: this.value,
        currency: this.currency,
        locale: this.language,
      });
    },
  },
};
