import { removeMultipleSpaces } from "@/utils/string";

export default {
  name: "NumericValueTransition",
  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  computed: {
    valueWithoutMultipleSpaces() {
      if (!this.value) {
        return this.placeholder || "";
      }

      return removeMultipleSpaces(this.value.trim());
    },
  },
  methods: {
    generateKey(val, idx) {
      return `${val}-${idx}`;
    },
  },
};
