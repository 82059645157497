export default {
  name: "Counter",
  props: {
    count: {
      type: Number,
      default: 10,
    },
    redirectUrl: {
      type: String,
    },
  },
  data() {
    return {
      current: this.count,
    };
  },
  watch: {
    current: {
      immediate: true,
      handler(value) {
        if (value > 0) {
          setTimeout(() => this.current--, 1000);
        } else {
          this.redirectUrl && window.location.assign(this.redirectUrl);
        }
      },
    },
  },
};
