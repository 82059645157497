import NumericValueTransition from "./NumericValueTransition/NumericValueTransition.vue";

import { exactValueValidator } from "@/utils/props-validators";

const SIZE = Object.freeze({
  medium: "medium",
  large: "large",
});

export default {
  name: "CardField",
  components: {
    NumericValueTransition,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    valueSize: {
      type: String,
      validator: exactValueValidator(SIZE),
      default: SIZE.medium,
    },
    keepPlaceholder: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    isNumeric: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isLarge() {
      return this.valueSize === SIZE.large;
    },
    customClass() {
      return {
        "is-large": this.isLarge,
        "is-inline": this.inline,
      };
    },
  },
};
