import { mapState } from "vuex";
import qiwaLogoEn from "@/assets/images/qiwa-logo-white-en.svg";
import qiwaLogoAr from "@/assets/images/qiwa-logo-white-ar.svg";

import CardField from "./CardField/CardField.vue";
import BrandLogo from "./BrandLogo/BrandLogo.vue";
import { formatString } from "@/utils/string";
import { creditCardFormValidator } from "./CreditCard.utils";

export default {
  name: "CreditCard",
  components: {
    CardField,
    BrandLogo,
  },
  props: {
    form: {
      type: Object,
      validator: creditCardFormValidator,
      required: true,
    },
    flip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      language: state => state.i18n.language,
    }),
    logo() {
      return this.language === "ar" ? qiwaLogoAr : qiwaLogoEn;
    },
  },
  methods: {
    formatDate(value) {
      return formatString(value, "##/##", "MM/YY");
    },
  },
};
