<script>
import { logger } from "@/services/logger";

export default {
  name: "ErrorBoundary",
  data: () => ({
    error: false,
  }),
  errorCaptured(_err, _vm, _info) {
    this.error = true;
    logger.logError({ message: `Error boundary: ${_info}`, error: _err });
  },
  render(h) {
    if (this.error) {
      return this.$slots?.error?.[0] || h("p", "Something went wrong");
    }
    return this.$slots?.default?.[0];
  },
};
</script>
