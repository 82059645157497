export default {
  name: "BrandLogo",
  props: {
    name: {
      type: String,
    },
  },
  mounted() {
    this.setImage();
  },
  data: () => ({
    imageSrc: null,
  }),
  computed: {
    hasImage() {
      return !!this.imageSrc;
    },
  },
  methods: {
    async setImage() {
      try {
        const lowerCaseName = this.name.toLowerCase();
        const module = await import(`@/assets/images/brands/${lowerCaseName}.svg`);
        this.imageSrc = module.default;
      } catch {
        this.handleError();
      }
    },
    handleError() {
      this.imageSrc = null;
    },
  },
  watch: {
    name() {
      this.setImage();
    },
  },
};
