import Message from "@/components/Message/Message.vue";

export default {
  name: "ErrorMessage",
  components: {
    Message,
  },
  props: {
    errorMessage: {
      type: String,
    },
    transactionId: {
      type: String,
    },
  },
};
