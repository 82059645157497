import { mapState, mapActions } from "vuex";

import * as types from "@/types/store";
import { qiwaStatus } from "@/utils/qiwa";

const PAYMENT_TYPES = {
  none: "none",
  card: "card",
  sadad: "sadad",
};

export default {
  name: "SelectPayment",
  created() {
    this.PAYMENT_TYPES = PAYMENT_TYPES;
    const { transactionId } = this.$route.query;

    if (!transactionId) {
      this.redirectToDashboard();
    } else {
      this.getStatus(transactionId);
    }
  },
  data() {
    return {
      paymentType: "none",
      price: null,
      areTermsConfirmed: false,
      isSadadAvailable: false,
    };
  },
  computed: {
    ...mapState({
      loading: state => state.paymentStatus.loading,
      status: state => state.paymentStatus.status,
      priceDetails: state => state.paymentPrice.details,
      loadingPrice: state => state.paymentPrice.loading,
    }),
    status() {
      return qiwaStatus(this.loading || this.loadingPrice);
    },
    isDisabled() {
      return !this.paymentType || this.paymentType === PAYMENT_TYPES.none || !this.areTermsConfirmed;
    },
  },
  methods: {
    ...mapActions({
      getPaymentStatus: types.ACTION_PAYMENT_STATUS_GET,
      getPrice: types.ACTION_PAYMENT_PRICE_GET,
      getSadadStatus: types.ACTION_GET_SADAD_STATUS,
      createSadadPayment: types.ACTION_CREATE_SADAD,
    }),
    async getStatus(id) {
      const [paymentStatusResponse, getPriceResponse, getSadadStatusResponse] = await Promise.all([
        this.getPaymentStatus(id),
        this.getPrice(id),
        this.getSadadStatus(id),
      ]);

      const { price } = getPriceResponse;
      this.price = price;

      const { status } = getSadadStatusResponse;
      this.isSadadAvailable = status.available;

      if (paymentStatusResponse.error || getPriceResponse.error) {
        const error = paymentStatusResponse.error || getPriceResponse.error;
        const message = error.message || "CORE.INFO.ERROR_OCCURRED";
        this.$buefy.toast.open({ message: this.$t(message), type: "is-danger", duration: 5000 });
        this.redirectToDashboard();
      }
    },
    redirectToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    selectPaymentType(paymentType) {
      this.paymentType = paymentType;
    },
    async next() {
      const { transactionId } = this.$route.query;

      if (this.paymentType === PAYMENT_TYPES.card) {
        this.$router.push({ name: "paymentCheckout", query: { transactionId } });
      }

      if (this.paymentType === PAYMENT_TYPES.sadad) {
        const gateUrl = await this.createSadadPayment(transactionId);
        window.location = gateUrl;
      }
    },
  },
};
