import { isVueAppLocal, isVueAppDevelop, isVueAppStage } from "@/utils/environments";

const IFRAME_FOCUS_EVENT = "iframeCommunication::onFocus";
const CVV_IFRAME_NAME = "card.cvv";

export const FLIP_CART_EVENT = { type: "flip", value: true };

export const removeAllChildren = element => {
  while (element.firstChild) {
    element.firstChild.remove();
  }
};

export const registerForm = (parent, redirectUrl, widgetBrands) => {
  removeAllChildren(parent);

  const formEl = document.createElement("form");
  formEl.setAttribute("class", "paymentWidgets");
  formEl.setAttribute("action", redirectUrl);
  formEl.setAttribute("data-brands", widgetBrands);

  parent.appendChild(formEl);
};

export const shouldFlipCard = event => {
  try {
    const data = typeof event.data === "string" && JSON.parse(event.data);
    const isOnFocusEvent = data?.method === IFRAME_FOCUS_EVENT;
    const targetName = event.target?.document?.activeElement?.name;

    return isOnFocusEvent && targetName === CVV_IFRAME_NAME;
  } catch (_) {
    return false;
  }
};

export const createWpwlOptions = lang => {
  const paymentTargetOption = isVueAppLocal || isVueAppDevelop || isVueAppStage ? { paymentTarget: "_top" } : {};

  return {
    locale: lang,
    style: "logos",
    ...paymentTargetOption,
    brandDetection: true,
    onReady() {
      try {
        const cardHolder = document.querySelector(".wpwl-control-cardHolder");
        const cardDate = document.querySelector(".wpwl-control-expiry");
        const mada =
          document.querySelector(".wpwl-control-brand") &&
          document.querySelector(".wpwl-control-brand").querySelector('option[value="MADA"]');

        const button = document.querySelector(".wpwl-button");

        if (button) {
          button.style.width = "100%";
        }

        if (mada) {
          const madaDisplayName = {
            en: "mada debit card",
            ar: "بطاقة مدى البنكية",
          };

          mada.text = madaDisplayName[window.wpwlOptions.locale || "en"];
        }

        if (cardHolder) {
          cardHolder.addEventListener("input", event => {
            window &&
              window.parent &&
              window.parent.postMessage(
                { type: "change", key: "name", value: event && event.target && event.target.value },
                "*",
              );
          });
        }

        if (cardDate) {
          cardDate.addEventListener("input", event => {
            window &&
              window.parent &&
              window.parent.postMessage(
                { type: "change", key: "date", value: event && event.target && event.target.value },
                "*",
              );
          });
        }
      } catch (_) {}
    },
    onLoadThreeDIframe() {
      try {
        window && window.parent && window.parent.postMessage({ type: "hide-card" }, "*");
      } catch (_) {}
    },
    onChangeBrand(value, _) {
      try {
        window && window.parent && window.parent.postMessage({ type: "change", key: "brand", value }, "*");
      } catch (_) {}
    },
    onBlurSecurityCode() {
      try {
        window && window.parent && window.parent.postMessage({ type: "flip", value: false }, "*");
      } catch (_) {}
    },
  };
};
